import { PermissionAction } from '../permission/permissions.model';
import { GasMonitorReading, GasSensorReading, InstrumentStatus, SensorGasCode, SensorStatus, SensorUnit } from '../gas/gas';
import { ConfinedSpacePermit } from '../item/confined-space';
import { AnyItem, ItemType } from '../item/item';
import { LocationDetails } from '../location/location-update';
import { Point } from '../map/location';
import { ConnectivityStatus } from './badge-update';
import { MessageAttachment } from '../channel/channel-message';
import { WranglerPlacement } from '../walt/walt';

export type EntryEventTypes = EventType.EntryEnter | EventType.EntryExit | EventType.EntryAttendantIn
    | EventType.EntryAttendantOut | EventType.EntryWilmaIn | EventType.EntryWilmaOut | EventType.EntryEntrant | EventType.EntryLeft;
export type WaltEventTypes = EventType.WaltAssigned | EventType.WaltUnassigned | EventType.WaltDocked | EventType.WaltUndocked
    | EventType.WaltLogin | EventType.WaltLogout | EventType.WaltLocation | EventType.WaltFacilityChange;

export interface Event extends AbstractEvent {
    location?: [number, number];
    level?: number;
    accuracy?: number;
    offline?: boolean;
    stationary?: boolean;
    engine?: boolean;
    battery?: number;
    network?: NetworkStatus;
    connectivity?: ConnectivityStatus;
    panic?: boolean;
    message?: string;
    gasMonitor?: GasMonitorReading;
    peers?: Array<{
        id?: string;
        distance?: number;
        rssi?: number;
    }>;
    beacons?: Array<{
        id?: string;
        distance?: number;
        rssi?: number;
    }>;
    locationDate?: Date;
    lastMoving?: Date;
    lastInVehicle?: Date;
    locationType?: string;
    deviceType?: 'walt' | 'android' | 'ios' | 'wisp';
    deviceId?: string;
    image?: {
        id?: string;
        image?: string;
        thumbnail?: string;
        imageIndex?: string;
        date?: Date;
        video?: string;
        duration?: number;
        sequence?: number;
        encoding?: string;
    };
    grantedLocationPermissions?: {
        fine: boolean;
        coarse: boolean;
        background: boolean;
        motion?: boolean;
    };
    locationDetails?: LocationDetails;
}

export type EventId = {
    itemId?: string;
    wilmaId?: string;
    personId?: string;
    beaconId?: string;
    waltId?: string;
    date: Date;
    id: string;
};

export interface AbstractEvent {
    id?: Partial<EventId>;
    facilityId?: string;
    facilityIds?: string[];
    registrationId?: string; // wisp id
    type?: EventType;
    folderId?: string;
    personName?: string;
    personTags?: string[];
    personCrafts?: string[];
    personCompany?: string;
    itemName?: string;
    itemTags?: string[];
}

export interface NetworkStatus {
    wanDevices: NetworkWanDevice[];
    gps: NetworkWanGps;
    updated?: Date;
    status?: NetworkStatusType;
}

export interface NetworkWanDevice {
    id: string;
    type: NetworkWanDeviceType;
    name?: string;
    sim?: string;
    carrier?: string;
    dbm?: string;
    rfBand?: string;
    serviceType?: string;
    ssid?: string; // only for wifi wan
}

export enum NetworkWanDeviceType {
    Cellular = 'cellular',
    Ethernet = 'ethernet',
    Wifi = 'wifi',
    Unknown = 'unknown',
}

export interface NetworkWanGps {
    location: number[];
    time: string;
}

export enum NetworkStatusType {
    Disconnected = 'disconnected',
    Private = 'private',
    Verizon = 'verizon',
    Other = 'other',
}

export enum EventType {
    AirReadingSubmission = 'air-reading-submission',
    BeaconLowBattery = 'beacon-low-battery',
    BeaconDisconnect = 'beacon-disconnect',
    BeaconEnter = 'beacon-enter',
    BeaconExit = 'beacon-exit',
    ConfinedSpacePermit = 'confined-space-permit',
    EngineOff = 'engine-off',
    EngineOn = 'engine-on',
    EntryAttendantIn = 'entry-attendant-in',
    EntryAttendantOut = 'entry-attendant-out',
    EntryEnter = 'entry-enter',
    EntryEntrant = 'entry-entrant',
    EntryExit = 'entry-exit',
    EntryImage = 'entry-image',
    EntryLeft = 'entry-left',
    EntryMotion = 'entry-motion',
    EntryWilmaIn = 'entry-wilma-in',
    EntryWilmaOut = 'entry-wilma-out',
    FacilityEnter = 'facility-enter',
    FacilityExit = 'facility-exit',
    FormAnswerSubmission = 'form-answer-submission',
    FormSubmission = 'form-submission',
    GasCodeReading = 'gas-code-reading',
    GasInstrumentReading = 'gas-instrument-reading',
    GasLevelReading = 'gas-level-reading',
    GasMonitorPair = 'gas-monitor-pair',
    GasMonitorUnpair = 'gas-monitor-unpair',
    GasMonitorReading = 'gas-monitor-reading',
    GeofenceDisconnect = 'geofence-disconnect',
    GeofenceEnter = 'geofence-enter',
    GeofenceExit = 'geofence-exit',
    LevelEnter = 'level-enter',
    LevelExit = 'level-exit',
    MessageDelivered = 'message-delivered',
    MobileLocationDenied = 'mobile-location-denied',
    MobileLogin = 'mobile-login',
    MobileLogout = 'mobile-logout',
    Moving = 'moving',
    None = 'none',
    PanicReceived = 'panic-received',
    PeerEnter = 'peer-enter',
    PeerExit = 'peer-exit',
    SafetyObservationResolved = 'safety-observation-resolved',
    SafetyObservationSubmitted = 'safety-observation-submitted',
    SafetyObservationUpdated = 'safety-observation-updated',
    Stationary = 'stationary',
    WaltAssigned = 'walt-assigned',
    WaltDocked = 'walt-docked',
    WaltLocation = 'walt-location',
    WaltLogin = 'walt-login',
    WaltLogout = 'walt-logout',
    WaltUnassigned = 'walt-unassigned',
    WaltFacilityChange = 'walt-facility-change',
    WaltUndocked = 'walt-undocked',
    WilmaAssigned = 'wilma-assigned',
    WilmaBattery = 'wilma-battery',
    WilmaCharging = 'wilma-charging',
    WilmaNetworkChange = 'wilma-network-change',
    WilmaUnassigned = 'wilma-unassigned',
}


export type BadgeLocation = [number, number];

export enum WorkflowActionType {
    Alert = 'alert',
    Post = 'post',
    Form = 'form',
    Report = 'report',
    Sms = 'sms',
    Email = 'email',
    Notify = 'notify',
    Tag = 'tag',
    FacilityAlert = 'facility-alert',
}

export interface AirReadingSubmissionEvent extends AbstractEvent {
    type: EventType.AirReadingSubmission;
    airReadingSubmissionId?: string;
    itemId?: string;
    permitNumber?: string;
    entryId?: string;
}


export interface ConfinedSpacePermitEvent extends AbstractEvent {
    type: EventType.ConfinedSpacePermit;
    confinedSpacePermitCloseOpen?: {
        closed?: Date;
        created?: Date;
        itemId: string;
        permitNumber: string;
        hwSessionId?: string;
    };
}

export interface EntryEvent extends AbstractEvent {
    type: EntryEventTypes;
    personId?: string;
    itemId?: string;
    entryId?: string;
    confinedSpacePermit?: ConfinedSpacePermit;
    initiatorId?: string;
    reason?: string;
    wilmaId?: string;
    holeWatchSwap?: boolean;
    attendantIn?: Date;
    entryIn?: Date;
}

export interface WilmaEvent extends AbstractEvent {
    type: EventType.WilmaAssigned | EventType.WilmaUnassigned;
    itemId?: string;
    entryId?: string;
    wilmaId?: string;
    wilmaName?: string;
    itemTags?: string[];
    networkStatus?: NetworkStatusType;
}

export interface EventUpdateResponse {
    date: Date;
    personId?: string;
    deviceId?: string;
    events?: AnyBadgeEvent[];
    itemId?: string;
    registrationId?: string;
    gasMonitor?: GasMonitorReading;
    panic?: boolean;
    location?: Point;
    locationDetails?: LocationDetails;
    facilities?: Array<{ id: string }>;
    lastMoving?: Date;
    lastInVehicle?: Date;
}

export interface FormSubmissionEvent extends AbstractEvent {
    type: EventType.FormSubmission;
    formSubmissionId?: string;
    formId?: { id: string, version: string };
    formName?: string;
    formTags?: string[];
    itemId?: string;
    permitNumber?: string;
    entryId?: string;
}

export interface GasMonitorEvent extends AbstractEvent {
    type: EventType.GasCodeReading | EventType.GasInstrumentReading;
    instrumentStatus?: InstrumentStatus;
    sensorCode?: SensorGasCode;
    sensorStatus?: SensorStatus;
    sensorUnit?: SensorUnit;
    sensorValue?: number;
}

export interface GeofenceEvent extends AbstractEvent {
    type: EventType.GeofenceEnter | EventType.GeofenceExit | EventType.GeofenceDisconnect;
    disconnect?: boolean;
    geofenceId?: string;
    geofenceName?: string;
    geofenceTags?: string[];
    geofenceEntered?: Date;
    geofenceTypeId?: string;
    geofenceCategories?: string[];
    visiting?: string;
}

export interface LogoutEvent extends AbstractEvent {
    type: EventType.MobileLogout;
    geoFenceId?: string;
    geoFenceName?: string;
    geoFenceTags?: string[];
}

export interface PanicEvent extends AbstractEvent {
    type: EventType.PanicReceived;
    message?: string;
    location?: [number, number];
    offline?: boolean;
    geofenceId?: string;
    geofenceName?: string;
}

export interface UpdateEvent {
    date: Date;
    personId?: string;
    type?: EventType;
}

export type EventNameLookUpOverrides = { [type in EventType]?: <T extends AnyBadgeEvent>(row: T) => string };


export interface ItemUpdate {
    items?: { [key: string]: AnyItem };
    itemTypes?: { [key: string]: ItemType };
}

export const eventTypeToPermissions: { [key in EventType]?: PermissionAction } = {
    [EventType.FormSubmission]: PermissionAction.ViewFormSubmissions,
};

export const mapExitToDisconnectEvent = (x: AnyBadgeEvent[]) => x.map(e => {
    if ((e as GeofenceEvent).disconnect) {
        switch (e.type) {
            case EventType.GeofenceExit:
                e.type = EventType.GeofenceDisconnect;
                break;
            case EventType.BeaconExit:
                e.type = EventType.BeaconDisconnect;
                break;
        }
    }
    return e;
});

export type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

export type RowEventMatch = EventType | RecursivePartial<AnyBadgeEvent>;

export enum RowEventType {
    FormSubmission,

    BeaconDisconnect,
    BeaconEnter,
    BeaconExit,

    GeofenceDisconnect,
    GeofenceEnter,
    GeofenceExit,

    ConfinedSpaceEnter,
    ConfinedSpaceExit,
    ConfinedSpaceAttendantIn,
    ConfinedSpaceAttendantOut,
    ConfinedSpaceAirReading,

    MobileLogout,
    Moving,
    Stationary,
    PeerEnter,
    PeerExit,
}

export interface RowEventDefinition {
    eventType: RowEventType;
    eventTitle: string;
    iconHtml: string;
    subEvents?: RowEventDefinition[];
}

export const EVENT_MATCHES: { [key in RowEventType]: RowEventMatch } = {
    [RowEventType.FormSubmission]: EventType.FormSubmission,

    [RowEventType.BeaconDisconnect]: EventType.BeaconDisconnect,
    [RowEventType.BeaconEnter]: EventType.BeaconEnter,
    [RowEventType.BeaconExit]: EventType.BeaconExit,
    [RowEventType.PeerEnter]: EventType.PeerEnter,
    [RowEventType.PeerExit]: EventType.PeerExit,

    [RowEventType.GeofenceDisconnect]: EventType.GeofenceDisconnect,
    [RowEventType.GeofenceEnter]: EventType.GeofenceEnter,
    [RowEventType.GeofenceExit]: EventType.GeofenceExit,

    [RowEventType.ConfinedSpaceEnter]: EventType.EntryEnter,
    [RowEventType.ConfinedSpaceExit]: EventType.EntryExit,
    [RowEventType.ConfinedSpaceAttendantIn]: EventType.EntryAttendantIn,
    [RowEventType.ConfinedSpaceAttendantOut]: EventType.EntryAttendantOut,
    [RowEventType.ConfinedSpaceAirReading]: EventType.AirReadingSubmission,

    [RowEventType.MobileLogout]: EventType.MobileLogout,
    [RowEventType.Stationary]: EventType.Stationary,
    [RowEventType.Moving]: EventType.Moving,
};

export const GEOFENCE_EVENT_TYPES = [
    RowEventType.GeofenceDisconnect,
    RowEventType.GeofenceEnter,
    RowEventType.GeofenceExit,
];

export const BEACON_EVENT_TYPES = [
    RowEventType.BeaconDisconnect,
    RowEventType.BeaconEnter,
    RowEventType.BeaconExit,
];

export const CONFINED_SPACE_EVENT_TYPES = [
    RowEventType.ConfinedSpaceEnter,
    RowEventType.ConfinedSpaceExit,
    RowEventType.ConfinedSpaceAttendantIn,
    RowEventType.ConfinedSpaceAttendantOut,
    RowEventType.ConfinedSpaceAirReading,
];

export interface MotionEvent extends AbstractEvent {
    type: EventType.EntryMotion;
    image?: string;
    thumbnail?: string;
    video?: string;
    duration?: number;
    sequence?: number;
    encoding?: string;
    imageIndex?: string;
    itemId?: string;
    entryId?: string;
    wilmaName?: string;
}

export interface ImageEvent {
    id?: string;
    date?: string;
    image?: string;
    thumbnail?: string;
    video?: string | Promise<Uint8Array>;
    duration?: number;
    sequence?: number;
    encoding?: string;
    imageIndex?: string;
    itemId?: string;
    entryId?: string;
    wilmaName?: string;
}

export interface SafetyObservationEvent extends AbstractEvent {
    type: EventType.SafetyObservationResolved | EventType.SafetyObservationSubmitted | EventType.SafetyObservationUpdated;
    geofenceId?: string;
    userResponse?: string;
    hazardLevel?: 'low' | 'medium' | 'high'; // leaving in for trigger case
    safetyObservationId?: string;
    attachments?: MessageAttachment[];
}

export interface FacilityEvent extends AbstractEvent {
    type: EventType.FacilityEnter | EventType.FacilityExit;
    facilityId?: string;
    facilityName?: string;
    facilityTags?: string[];
    facilityEntered?: Date;
    disconnect?: boolean;
}

export interface GasMonitorReadingChangesEvent extends AbstractEvent {
    type: EventType.GasMonitorReading;
    readings: GasSensorReading[];
    geofenceId?: string;
    changes: Array<Partial<GasMonitorEvent>>;
}

export interface BeaconEvent extends AbstractEvent {
    type: EventType.BeaconEnter | EventType.BeaconExit | EventType.BeaconDisconnect;
    beaconId?: string;
    beaconName?: string;
    beaconLevel?: number;
    beaconEntered?: Date;
    disconnect?: boolean;
}

export interface BeaconLowBatteryEvent extends AbstractEvent {
    type: EventType.BeaconLowBattery;
    beaconId?: string;
    beaconName?: string;
    beaconLevel?: number;
    battery?: number;
    facilityId?: string;
}

export interface PeerEvent extends AbstractEvent {
    type: EventType.PeerEnter | EventType.PeerExit;
    peerId?: string;
    peerEntered?: Date;
    disconnect?: boolean;
}

export interface LoginEvent extends AbstractEvent {
    type: EventType.MobileLogin;
}

export interface WaltEvent extends AbstractEvent {
    type: WaltEventTypes;
    personId?: string;
    wrangler: WranglerPlacement;
}

export interface LevelEvent extends AbstractEvent {
    type: EventType.LevelEnter | EventType.LevelExit;
    level: number;
}

export interface WilmaAssignmentEvent extends AbstractEvent {
    type: EventType.WilmaAssigned | EventType.WilmaUnassigned;
    entryId: string;
    itemId: string;
    wilmaId: string;
}

type EventIgnores = 'type' | 'id';

// convenient type with all event properties possible for the backend record
export type AnyEvent =
    Omit<Event, EventIgnores> &
    Omit<AirReadingSubmissionEvent, EventIgnores> &
    Omit<FormSubmissionEvent, EventIgnores> &
    Omit<EntryEvent, EventIgnores> &
    Omit<ConfinedSpacePermitEvent, EventIgnores> &
    Omit<GeofenceEvent, EventIgnores> &
    Omit<FacilityEvent, EventIgnores> &
    Omit<PanicEvent, EventIgnores> &
    Omit<GasMonitorEvent, EventIgnores> &
    Omit<GasMonitorReadingChangesEvent, EventIgnores> &
    Omit<MotionEvent, EventIgnores> &
    Omit<WilmaEvent, EventIgnores> &
    Omit<BeaconEvent, EventIgnores> &
    Omit<BeaconLowBatteryEvent, EventIgnores> &
    Omit<PeerEvent, EventIgnores> &
    Omit<LoginEvent, EventIgnores> &
    Omit<LogoutEvent, EventIgnores> &
    Omit<WaltEvent, EventIgnores> &
    Omit<SafetyObservationEvent, EventIgnores> & {
    id: EventId;
    type: EventType;
};

export type EventResponse = Omit<AnyEvent, 'id'> & { id: string, date: Date };

export interface BadgeEvent extends Omit<AbstractEvent, 'id'> {
    id?: string;
    personId?: string;
    date?: string;

    location?: BadgeLocation;
    level?: number;
    accuracy?: number;
    battery?: number;
    connectivity?: ConnectivityStatus;
    panic?: boolean;
    message?: string;
    gasMonitor?: GasMonitorReading;

    itemName?: string; // Not part of backend model
    formName?: string; // Not part of backend model
}

// convenient interface with all possible properties for frontend / mobile use, with sanitized properties
export type AnyBadgeEvent = BadgeEvent &
    Omit<Partial<AirReadingSubmissionEvent>, EventIgnores> &
    Omit<Partial<FormSubmissionEvent>, EventIgnores> &
    Omit<Partial<EntryEvent>, EventIgnores> &
    Omit<Partial<ConfinedSpacePermitEvent>, EventIgnores> &
    Omit<Partial<BeaconEvent>, EventIgnores> &
    Omit<Partial<GeofenceEvent>, EventIgnores> &
    Omit<Partial<PanicEvent>, EventIgnores> &
    Omit<Partial<WilmaEvent>, EventIgnores> &
    Omit<Partial<FacilityEvent>, EventIgnores> &
    Omit<Partial<LogoutEvent>, EventIgnores>;
