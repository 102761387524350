import { dateProp, idProp, model, Model, modelAction, prop } from '@weavix/mobx';
import { Cache } from '@weavix/utils/src/cache';
import { User, userRef } from '../users-store/users-store';
import { channelRef } from './channels-store';
import { ChannelMessageData, MessageType } from '@weavix/models/src/channel/channel-message';

const senderCache = new Cache<User>({ duration: 60000, jitter: 60000 });

@model('ChannelMessage')
export class ChannelMessage extends Model({
    id: idProp,
    channelId: prop<string>(),
    data: prop<ChannelMessageData | undefined>(),
    date: dateProp(),
    message: prop<string>(),
    type: prop<MessageType | undefined>(),
    senderId: prop<string>(),
    pending: prop<boolean>(),
    error: prop<boolean>(),
    partial: prop<boolean>(),
    translations: prop<{ [locale: string]: string }>(),
    translated: prop<boolean>(true),
    uploaded: dateProp(),
    sequence: prop<number>(),
    fromTeams: prop<boolean>(),
    edited: dateProp(),
}) {
    sameUserAsNext: boolean;
    sameUserAsPrevious: boolean;
    groupWithNext: boolean;
    groupWithLast: boolean;

    constructor(...args) {
        super(...args);
        this.senderId = args[0].sender?.id;
        if (document.location.hostname.includes('-canary.weavix')) {
            const fixUri = (uri: string) => {
                if (uri?.includes('-canary.weavix')) return uri;
                return uri?.replace('.weavix', '-canary.weavix');
            };
            this.data?.attachments?.forEach(attachment => {
                if (attachment.file?.uri) attachment.file.uri = fixUri(attachment.file.uri);
                if (attachment.thumbnailUri) attachment.thumbnailUri = fixUri(attachment.thumbnailUri);
            });
        }
        this.translated = this.hasTranslation;
    }

    get hasTranslation() {
        if (!this.translations) return false;
        const locale = localStorage.getItem('lang') || 'en';
        const found = Object.keys(this.translations).find(txLocale => {
            return txLocale.split('-')[0] === locale.split('-')[0];
        });
        return !!found;
    }

    get locale() {
        return this.data?.locale ?? null;
    }

    @modelAction
    toggleTranslation(translated?: boolean) {
        this.translated = translated ?? !this.translated;
    }

    get translatedMessage() {
        if (!this.translations || !this.translated) return this.message;
        const locale = localStorage.getItem('lang') || 'en';
        const found = Object.keys(this.translations).find(txLocale => {
            return txLocale.split('-')[0] === locale.split('-')[0];
        });
        return found ? this.translations[found] : this.message;
    }

    get sender() {
        if (!this.senderId) return null;
        const cached = senderCache.get(this.senderId);
        if (cached) return cached;

        let currentSender = userRef(this.senderId).maybeCurrent;
        if (!currentSender) {
            currentSender = new User({
                firstName: 'Unknown',
                lastName: 'User',
                id: this.senderId,
                deleted: true,
            }, true);
        }

        return senderCache.set(this.senderId, currentSender);
    }

    get channel() {
        return channelRef(this.channelId).maybeCurrent;
    }

    get isTeamsMessage() {
        return !!this.fromTeams;
    }

    @modelAction
    setError() {
        this.error = true;
    }
}
