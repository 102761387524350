import { PersonLike, PersonMatcher } from '../person/person-matcher';

export enum PermissionAction {
    // deprecated (5.3)
    UseWheresWalt = 'use-wheres-walt',
    WaltSettings = 'walt-settings',
    AwsTranscribe = 'aws-transcribe',

    // reserved for global-admins only. should not be assigned to other groups
    AdministerAccount = 'administer-account',

    /*
        This permission is automatically applied to entities that don't have their own
        view permission. All users have access to this permission so it does not need
        to be granted explicitly.
    */
    ViewGeneric = 'view-generic',

    // communication
    ViewDirectory = 'view-directory',
    UseVideoChat = 'use-video-chat',
    SubmitForms = 'submit-forms',
    ViewFormSubmissions = 'view-form-submissions', // at the moment, not directly assignable. see additionalGrantedActions below
    EnableHotspot = 'enable-hotspot',
    SendMedia = 'send-media',
    UseLockScreen = 'use-lock-screen',
    UseWalt = 'use-walt',
    UseMobileApp = 'use-mobile-app',
    UseWebRadioAndConsole = 'use-web-radio-and-console',

    // worker-diagnostics
    ViewWorkerLiveLook = 'view-worker-live-look',
    ViewWorkerEfficiency = 'view-worker-efficiency',

    // equipment-diagnostics
    ViewEquipmentLiveLook = 'view-equipment-live-look',

    // safety
    SendAlerts = 'send-alerts',
    ViewConfinedSpaces = 'view-confined-spaces',
    OperateConfinedSpaces = 'operate-confined-spaces',
    UseGasMonitors = 'use-gas-monitors',

    // configuration
    ViewAdministratorDefinedChannels = 'view-administrator-defined-channels',
    ViewAlertTypes = 'view-alert-types',
    ViewCrafts = 'view-crafts',
    ViewForms = 'view-forms',
    ViewGeofenceTypes = 'view-geofence-types',
    ViewItemTypes = 'view-item-types',
    ViewItems = 'view-items', // item permissions will be a part of sites
    ViewPeople = 'view-people',
    ViewRegistrationCodes = 'view-registration-codes',
    ViewTeamsIntegration = 'view-teams-integration',
    ViewSafetyObservation = 'view-safety-observation',
    ViewDashboards = 'view-dashboards',
    ViewDeviceManagement = 'view-device-management',
    EditDeviceManagement = 'edit-device-management',
    ViewWorkflows = 'view-workflows',
    EditAdministratorDefinedChannels = 'edit-administrator-defined-channels',
    EditAlertTypes = 'edit-alert-types',
    EditCrafts = 'edit-crafts',
    EditForms = 'edit-forms',
    EditGeofenceTypes = 'edit-geofence-types',
    EditItemTypes = 'edit-item-types',
    EditItems = 'edit-items', // item permissions will be a part of sites
    EditPeople = 'edit-people',
    EditRegistrationCodes = 'edit-registration-codes',
    EditSafetyObservation = 'edit-safety-observation',
    EditTeamsIntegration = 'edit-teams-integration',
    EditWorkflows = 'edit-workflows',

    // site permissions
    ViewSites = 'view-sites',
    ViewSiteLocationSettings = 'view-site-location-settings',
    ViewSiteNetworkSettings = 'view-site-network-settings',
    ViewSiteSafetySettings = 'view-site-safety-settings',
    ViewSiteSettings = 'view-site-settings',
    ViewPermissions = 'view-permissions',
    EditSites = 'edit-sites',
    EditSiteLocationSettings = 'edit-site-location-settings',
    EditSiteNetworkSettings = 'edit-site-network-settings',
    EditSiteSafetySettings = 'edit-site-safety-settings',
    EditSiteSettings = 'edit-site-settings',
    EditPermissions = 'edit-permissions',
    EditSiteSurvey = 'edit-site-survey',

    // features
    AzureTranscribe = 'azure-transcribe',
    LocalTranscribe = 'local-transcribe',
    VideoChatRecording = 'recording',
    VideoCalling = 'video-calling',
    VoiceCalling = 'voice-calling',
    SpeakTranslations = 'speak-translations',
    ReportSafetyObservation = 'report-safety-observation',
    MusicFeatureEnabled = 'music-feature-enabled',
    PowerBiReports = 'power-bi-reports',

    // user-specific permissions
    WaltAdminCodeAccess = 'walt-admin-code-access',
    AccessCanary = 'access-canary',
    EnableMusicOffSite = 'enable-music-off-site',
    EnableLocationDiagnostics = 'enable-location-diagnostics',
}

export const permissionActions = Object.values(PermissionAction);

export const ConsoleViewPermissions: PermissionAction[] = [
    PermissionAction.ViewConfinedSpaces,
    PermissionAction.ViewAdministratorDefinedChannels,
    PermissionAction.ViewAlertTypes,
    PermissionAction.ViewCrafts,
    PermissionAction.ViewForms,
    PermissionAction.ViewGeofenceTypes,
    PermissionAction.ViewItemTypes,
    PermissionAction.ViewPeople,
    PermissionAction.ViewPermissions,
    PermissionAction.ViewRegistrationCodes,
    PermissionAction.ViewSites,
    PermissionAction.ViewWorkflows,
    PermissionAction.ViewWorkerLiveLook,
    PermissionAction.ViewWorkerEfficiency,
    PermissionAction.ViewEquipmentLiveLook,
    PermissionAction.ViewDeviceManagement,
    PermissionAction.ViewSiteSettings,
    PermissionAction.ViewSiteLocationSettings,
    PermissionAction.ViewSiteNetworkSettings,
    PermissionAction.ViewSiteSafetySettings,
];

export interface GroupExclusion {
    action: PermissionAction;
    facilityId: string;
    geofenceId?: string[];
}

export interface AccountGroupExclusion extends GroupExclusion {
    accountId: string;
}

export interface PermissionApiResponse {
    enabledActions: PermissionAction[];
    grants: PermissionApiResponseItem[];
    waltSettings?: any;
    self?: PersonLike;
}

export interface PermissionApiResponseItem {
    /*
        "scope" specifies the situations in which the specified actions are granted.
        e.g. if facilityId is populated under the scope, then the user is only
        granted these actions under that facility.

        Any properties in the scope object have a narrowing effect on where the
        action is allowed.
    */
    scope: {
        accountId: string;

        /*
            Populated if this set of permissions only applies to a specific facility.
            The old app.weavix.com allows a folder to be added to individual facilities, but console.weavix.com does not support that at the moment.
        */
            facilityId?: string;

        /*
            Populated if this set of permissions only applies to a specific folder.
            It contains the primary folder ID and all recursive child IDs.
        */
        folderIds?: string[];

        /*
            Proposed future properties for segments. Empty/null implies all allowed. Otherwise,
            the permission only applies if the action is taken against one of the things listed
            (e.g. you can only add connections to people who have the specified craft).
            Segments will (tentatively) only apply to the following permissions:
                * Worker Efficiency
                * Add Connection
                * Create Channel
                * View Directory
                * Video Chat
        */
        // companies?: string[];
        // crafts?: string[];
        // geofences?: string[];
        // tags?: string[];
    };

    /*
        Actions the user is allowed to do within the specified scope.
    */
    grantedActions: PermissionAction[];

    /*
        Primary used to contain a list of permissions that don't apply to the specified geofences under a facility.
    */
    exclusions?: GroupExclusion[];

    // directory and livelook group limitations
    directory?: PersonMatcher;
    livelook?: PersonMatcher;

    /*
        Populated if this set of permissions came from a permission group, or empty if it came from a folder
        This is here for informative purposes but should not be used directly to see if someone is allowed
        to do something. Instead, anything that requires a permission should have a PermissionAction that grants
        that specific thing.
    */
    groupId?: string;
}
